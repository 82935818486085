

import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class TextAreaV1 extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}

mount() {
    this.textarea = this.element.querySelector('.elTextarea');
    const label = this.element.querySelector('.elLabel');

    this.addTextareaListeners(this.textarea)
    this.update()
  }

  update() {
    if (this.textarea.value) {
      this.textarea.classList.add('hasValue')
      this.element.classList.add('hasValue')
    } else {
      this.textarea.classList.remove('hasValue')
      this.element.classList.remove('hasValue')
    }
  }

  addTextareaListeners(textarea, re){
    // Add class 'elInputFocused' when textarea is focused
    textarea.addEventListener('focus', () => {
      this.element.querySelector('[data-input-status-type]').innerHTML = ''
      this.element.classList.remove("elInputValid")
      this.element.classList.remove("elInputError")
      this.element.classList.remove("elInputWarning")
      this.element.classList.add('elInputFocused');
    })
    // Handle On blur event to validate textarea and add/remove class - 'hasValue'
    textarea.addEventListener('blur', () => {
      this.element.classList.remove('elInputFocused');
      this.update()
      if (textarea.classList.contains('required1') && textarea.value === '') {
        return
      }
    })
  }



}

registerComponent('TextArea/V1', TextAreaV1)
window["TextAreaV1"] = TextAreaV1

